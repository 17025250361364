import './additionalText.scss';
window.addEventListener("DOMContentLoaded", function () {
  "use strict";

  var additionalHref = document.querySelector(".js-additional-text__href"),
      additionalText = document.querySelector(".js-additional-text__main");

  if (!additionalHref) {
    return;
  }

  additionalHref.addEventListener("click", function (e) {
    e.preventDefault();

    if (additionalText.classList.contains("show")) {
      additionalText.classList.remove("show");
      additionalHref.textContent = "\u0427\u0438\u0442\u0430\u0442\u044C \u0434\u0430\u043B\u0435\u0435";
    } else {
      additionalText.classList.add("show");
      additionalHref.textContent = "\u0421\u043A\u0440\u044B\u0442\u044C";
    }
  });
});
export default "additionalText";